/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-toolbar {
    --background:var(--ion-color-primary);
    --color: #fff;

    ion-icon {
        margin: 0 auto;
    }

    ion-button {
        --color: #fff;
    }

    ion-back-button {
        color: #fff;
    }
}

ion-item {
    --background: transparent;
    --padding-start: 0;
    --ion-safe-area-right: 0;
}

ion-fab-button {
    ion-icon {
        color: #000 !important;
    }

    button {
        ion-icon {
            color: #fff !important;
        }
    }
    color: #fff;
}

ion-fab-button[data-desc] {
    position: relative;

    ion-icon {
        color: var(--ion-color-primary) !important;
    }
  }
  
  ion-fab-button[data-desc]::after {
    position: absolute;
    content: attr(data-desc);
    z-index: 1;
    right: 55px;
    bottom: 4px;
    background-color: var(--ion-color-primary);
    padding: 9px;
    border-radius: 15px;
    color: white;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12);
  }

  .ios {
    //-- Validation failed
    ion-item.ion-invalid.ion-dirty ion-input{
        border-bottom: 2px solid var(--ion-color-danger) !important;
    }

    //-- Validation passed
    ion-item.ion-valid.ion-dirty ion-input{
        border-bottom: 2px solid var(--ion-color-success) !important;
    }
}

ion-datetime {
    color: black;
}

.refresher-ios .refresher-pulling-icon, .refresher-ios .refresher-pulling-text, .refresher-ios .refresher-refreshing-icon, .refresher-ios .refresher-refreshing-text {
    color: var(--ion-text-color,#FFF);
}

.alert-radio-label.sc-ion-alert-md {
    white-space: pre-wrap !important;
}